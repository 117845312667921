import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../../lib/supabaseClient";
import CameraCapture from "../../components/CameraCapture";
import Swal from "sweetalert2";
import "./FormFiller.css";

function FormFiller() {
  const { formId } = useParams();
  const [form, setForm] = useState(null);
  const [answers, setAnswers] = useState({});
  const [fileUploads, setFileUploads] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const fetchForm = async () => {
      const { data, error } = await supabase
        .from("forms")
        .select("*")
        .eq("id", formId)
        .single();

      if (error) {
        Swal.fire("Error", "Failed to load form: " + error.message, "error");
      } else {
        setForm(data);
      }
      setLoading(false);
    };

    fetchForm();
  }, [formId]);

  const handleChange = (label, value) => {
    setAnswers((prev) => ({ ...prev, [label]: value }));
  };

  const handleCheckboxChange = (label, option) => {
    const current = answers[label] || [];
    const updated = current.includes(option)
      ? current.filter((v) => v !== option)
      : [...current, option];
    setAnswers((prev) => ({ ...prev, [label]: updated }));
  };

  const shouldShowField = (field) => {
    if (!field.condition) return true;
    const targetValue = answers[field.condition.field];
    return targetValue === field.condition.value;
  };

  const uploadFile = async (file) => {
    const filePath = `uploads/${Date.now()}_${file.name}`;
    const { error } = await supabase.storage
      .from("platipus-uploads")
      .upload(filePath, file, {
        cacheControl: "3600",
        upsert: false,
      });

    if (error) {
      console.error("Upload error:", error);
      return null;
    }
    return filePath;
  };

  const validateForm = () => {
    const requiredFields = form.fields.filter((f) => f.required);
    for (const field of requiredFields) {
      const value = answers[field.label];
      if (
        (field.type === "checkbox" && (!value || value.length === 0)) ||
        (field.type === "file" && !fileUploads[field.label]) ||
        (field.type === "camera" && !fileUploads[field.label]) ||
        (!["checkbox", "file", "camera"].includes(field.type) && !value)
      ) {
        Swal.fire("Oops!", `Field "${field.label}" is required.`, "error");
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const updatedAnswers = { ...answers };
    for (const [label, file] of Object.entries(fileUploads)) {
      const uploadedPath = await uploadFile(file);
      if (uploadedPath) {
        updatedAnswers[label] = uploadedPath;
      }
    }

    const { error } = await supabase.from("responses").insert([
      {
        form_id: formId,
        answers: updatedAnswers,
        created_at: new Date().toISOString(),
      },
    ]);

    if (error) {
      Swal.fire("Error", "Failed to submit: " + error.message, "error");
    } else {
      setSubmitted(true);
      setAnswers({});
      setFileUploads({});
    }
  };

  if (loading) return <p className="loading">Loading...</p>;
  if (!form) return <p className="loading">Form not found.</p>;

  if (submitted) {
    return (
      <div className="form-container text-center">
        <h3 className="success-message">✅ Form berhasil dikirim!</h3>
        <button className="button" onClick={() => {
          setSubmitted(false);
          setAnswers({});
          setFileUploads({});
        }}>
          Isi Lagi
        </button>
      </div>
    );
  }

  return (
    <div className="form-container">
      <h2 className="form-title">{form.name}</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {form.fields.map((field, i) =>
          shouldShowField(field) ? (
            <div key={i} className="form-group">
              <label className="form-label">
                {field.label}
                {field.required && <span className="required">*</span>}
              </label>

              {field.type === "text" && (
                <input
                  type="text"
                  value={answers[field.label] || ""}
                  onChange={(e) => handleChange(field.label, e.target.value)}
                  className="form-input"
                />
              )}

              {field.type === "textarea" && (
                <textarea
                  value={answers[field.label] || ""}
                  onChange={(e) => handleChange(field.label, e.target.value)}
                  className="form-input"
                />
              )}

              {field.type === "file" && (
                <input
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setFileUploads((prev) => ({ ...prev, [field.label]: file }));
                      handleChange(field.label, file.name);
                    }
                  }}
                  className="form-input"
                />
              )}

              {field.type === "camera" && (
                <CameraCapture
                  onUpload={(file) => {
                    setFileUploads((prev) => ({ ...prev, [field.label]: file }));
                    handleChange(field.label, file.name);
                  }}
                />
              )}

              {field.type === "dropdown" && (
                <select
                  value={answers[field.label] || ""}
                  onChange={(e) => handleChange(field.label, e.target.value)}
                  className="form-input"
                >
                  <option value="">-- Pilih --</option>
                  {field.options.map((opt, idx) => (
                    <option key={idx} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select>
              )}

              {field.type === "radio" &&
                field.options.map((opt, idx) => (
                  <div key={idx} className="form-radio-option">
                    <input
                      type="radio"
                      name={field.label}
                      value={opt}
                      checked={answers[field.label] === opt}
                      onChange={() => handleChange(field.label, opt)}
                    />
                    <label>{opt}</label>
                  </div>
                ))}

              {field.type === "checkbox" &&
                field.options.map((opt, idx) => (
                  <div key={idx} className="form-checkbox-option">
                    <input
                      type="checkbox"
                      value={opt}
                      checked={answers[field.label]?.includes(opt) || false}
                      onChange={() => handleCheckboxChange(field.label, opt)}
                    />
                    <label>{opt}</label>
                  </div>
                ))}
            </div>
          ) : null
        )}

        <button type="submit" className="button">
          Submit
        </button>
      </form>
    </div>
  );
}

export default FormFiller;
