import React, { useState } from "react";
import { supabase } from '../../lib/supabaseClient';
import CameraCapture from "../../components/CameraCapture";
import { useNavigate } from "react-router-dom";
import "./FormBuilder.css";

function FormBuilder() {
  const [formName, setFormName] = useState("");
  const [fields, setFields] = useState([]);
  const [newFieldType, setNewFieldType] = useState("text");
  const navigate = useNavigate();

  const addField = () => {
    setFields([
      ...fields,
      {
        label: "",
        type: newFieldType,
        options: ["dropdown", "radio", "checkbox"].includes(newFieldType)
          ? ["Option 1"]
          : [],
        condition: null,
        imageURL: newFieldType === "camera" ? "" : null,
        required: false,
      },
    ]);
  };

  const updateField = (index, key, value) => {
    const updated = [...fields];
    updated[index][key] = value;
    setFields(updated);
  };

  const updateOption = (index, optIndex, value) => {
    const updated = [...fields];
    updated[index].options[optIndex] = value;
    setFields(updated);
  };

  const addOption = (index) => {
    const updated = [...fields];
    updated[index].options.push("New Option");
    setFields(updated);
  };

  const removeOption = (index, optIndex) => {
    const updated = [...fields];
    updated[index].options.splice(optIndex, 1);
    setFields(updated);
  };

  const updateCondition = (index, key, value) => {
    const updated = [...fields];
    updated[index].condition = {
      ...(updated[index].condition || {}),
      [key]: value,
    };
    setFields(updated);
  };

  const saveForm = async () => {
    if (!formName || fields.length === 0) {
      alert("Form name and at least one field is required!");
      return;
    }

    for (const field of fields) {
      if (field.required && !field.label) {
        alert("All required fields must have a label.");
        return;
      }
    }

    const { error } = await supabase.from("forms").insert([
      {
        name: formName,
        fields,
        created_at: new Date().toISOString(),
      },
    ]);

    if (error) {
      alert("Error saving form: " + error.message);
    } else {
      alert("Form saved successfully!");
      navigate("/admin");
    }
  };

  return (
    <div className="form-builder">
      <h2>Create New Form</h2>

      <input
        type="text"
        className="input"
        placeholder="Form Name"
        value={formName}
        onChange={(e) => setFormName(e.target.value)}
      />

      <div className="add-field-section">
        <h4>Add Field</h4>
        <div className="add-field-row">
          <select
            className="select"
            value={newFieldType}
            onChange={(e) => setNewFieldType(e.target.value)}
          >
            <option value="text">Text</option>
            <option value="textarea">Textarea</option>
            <option value="file">File Upload</option>
            <option value="dropdown">Dropdown</option>
            <option value="radio">Radio</option>
            <option value="checkbox">Checkbox</option>
            <option value="camera">Camera</option>
          </select>
          <button className="btn" onClick={addField}>Add</button>
        </div>
      </div>

      {fields.map((field, i) => (
        <div className="field-card" key={i}>
          <input
            className="input"
            type="text"
            placeholder="Label"
            value={field.label}
            onChange={(e) => updateField(i, "label", e.target.value)}
          />
          <div className="field-meta">
            <span className="field-type">({field.type})</span>
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={field.required}
                onChange={(e) => updateField(i, "required", e.target.checked)}
              />
              Required
            </label>
          </div>

          {["dropdown", "radio", "checkbox"].includes(field.type) && (
            <div className="options-section">
              <strong>Options:</strong>
              {field.options.map((opt, j) => (
                <div key={j} className="option-row">
                  <input
                    type="text"
                    className="input option-input"
                    value={opt}
                    onChange={(e) => updateOption(i, j, e.target.value)}
                  />
                  <button className="btn remove" onClick={() => removeOption(i, j)}>Remove</button>
                </div>
              ))}
              <button className="btn small" onClick={() => addOption(i)}>Add Option</button>
            </div>
          )}

          {field.type === "camera" && (
            <div className="camera-section">
              <CameraCapture
                onUpload={(url) => updateField(i, "imageURL", url)}
              />
              {field.imageURL && (
                <p>Image uploaded: <a href={field.imageURL} target="_blank" rel="noreferrer">View</a></p>
              )}
            </div>
          )}

          <div className="condition-section">
            <strong>Condition (Optional):</strong><br />
            Show if field
            <input
              type="text"
              className="input condition-input"
              placeholder="Field Label"
              value={field.condition?.field || ""}
              onChange={(e) => updateCondition(i, "field", e.target.value)}
            />
            equals
            <input
              type="text"
              className="input condition-input"
              placeholder="Expected Value"
              value={field.condition?.value || ""}
              onChange={(e) => updateCondition(i, "value", e.target.value)}
            />
          </div>
        </div>
      ))}

      <button className="btn save-btn" onClick={saveForm}>Save Form</button>
    </div>
  );
}

export default FormBuilder;
